<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-progress-linear
			:indeterminate="true"
			v-show="LoadingBillingProfiles"
		></v-progress-linear>
		<v-alert type="warning" v-if="BillingProfiles.length === 0">
			{{ $t("alertMissingBillingInfo") }}
		</v-alert>

		<v-row dense>
			<v-col
				cols="12"
				sm="6"
				lg="3"
				v-for="profile in BillingProfiles"
				:key="profile.BillingProfileId"
			>
				<v-card class="mb-4" :color="profile.IsActive ? '' : 'grey'">
					<v-card-text>
						<div class="mb-3 text--primary">
							<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.Name") }}:</span
								>{{ profile.Name }}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.Surname") }}:</span
								>{{ profile.Surname }}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("common.companyName") }}:</span
							>{{ profile.RagioneSociale }}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("common.fiscalCode") }}:</span
							>{{ profile.CodiceFiscale }}<br />
							<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.CodiceSDI") }}:</span
							>{{ profile.CodiceSDI }}<br />
							<span class="font-weight-bold pr-1">{{ $t("common.pIva") }}:</span
							>{{ profile.PartitaIVA }}<br />
							<span class="font-weight-bold pr-1"> {{ $t("common.address") }}:</span>
							{{ profile.Address }}
							{{ profile.ComuneId ? profile.ItalianCity.Name : profile.NonItalianCityName }}
							{{ profile.PostalCode }}
							<br />
							{{ profile.CountryId ? profile.Country.DescrizioneNazione : "-" }}
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-chip color="grey--darken-5" dark v-if="!profile.IsActive">{{
							$t("common.isItemActive.false")
						}}</v-chip>
						<v-btn
								icon
								@click="DeleteBillingProfile(profile.BillingProfileId)"
							>
								<v-icon>fas fa-trash</v-icon>
							</v-btn>
						<v-btn
							icon
							@click="ShowEditBillingProfileDialog(profile.BillingProfileId)"
						>
							<v-icon>fas fa-edit</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-btn block class="primary" @click="ShowNewBillingProfileDialog">{{
			$t("common.add")
		}}</v-btn>

		<AddEditBillingProfile
			v-model="showAddBillingProfile"
			:mode="dialogData.Mode"
			:UserId="UserId"
			:BillingProfileId="dialogData.BillingProfileId"
			@updated="OnBillingProfileUpdated"
		></AddEditBillingProfile>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

import AddEditBillingProfile from "./AddEditBillingProfile";
import Confirm from "@/components/Shared/Common/Confirm";

export default {
	$_veeValidate: { validator: "EditUserBillingProfile" },
	components: { AddEditBillingProfile, Confirm },

	props: {
		UserId: {
			type: String,
		},
	},
	data() {
		return {
			showAddBillingProfile: false,
			BillingProfiles: [],
			LoadingBillingProfiles: false,
			dialogData: {
				Mode: 1,
				BillingProfileId: null,
			},
		};
	},
	watch: {
		showAddBillingProfile: function (val) {
			if (!val) this.dialogData.BillingProfileId = null;
		},
	},
	created() {
		// add services to this
		if (this.UserId) {
			this.BillingProfileService = new CrudClient("SGP/Client/BillingProfiles");
		} else {
			this.BillingProfileService = new CrudClient(
				"SGP/Client/BillingProfilesSelf"
			);
		}
	},
	async mounted() {
		await this.LoadUserBillingProfiles();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async LoadUserBillingProfiles() {
			try {
				const userId = this.UserId;
				this.LoadingBillingProfiles = true;
				const res = await this.BillingProfileService.GetPaged({
					limit: 0,
					fields: "*, ItalianCity.Name, Country.DescrizioneNazione",
					filter: "IsActive:true",
					urlPostFix: this.UserId ? `User/${userId}` : "",
				});

				this.BillingProfiles = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingBillingProfiles = false;
			}
		},

		async OnBillingProfileUpdated() {
			this.showAddBillingProfile = false;
			await this.LoadUserBillingProfiles();
		},

		ShowNewBillingProfileDialog() {
			this.showAddBillingProfile = true;
			this.dialogData.Mode = 1;
			this.dialogData.BillingProfileId = null;
		},
		async ShowEditBillingProfileDialog(id) {
			this.showAddBillingProfile = true;
			this.dialogData.Mode = 2;
			this.dialogData.BillingProfileId = id;
		},

		async DeleteBillingProfile(billingProfileId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("confirmDeleteBillingInfo")
				))
			) {
				return;
			}

			try {
				await this.BillingProfileService.Patch(billingProfileId, { IsActive: false });
				this.snackSuccess({ Text: this.$t("common.success.deleteItem") });
				await this.LoadUserBillingProfiles();
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotUpdateData") });
				this.$captureError(error);
			} finally {
				this.LoadingBillingProfiles = false;
			}
		}
	},
};
</script>
<i18n>
{
	"it": {
		"addBillingProfile_Header": "Dati di Fatturazione",
		"NotActive": "Non attivo",
		"formBillingProfile":{
			"companyType_Company": "Azienda",
			"companyType_Personal": "Persona Fisica",
			"RagioneSociale": "Ragione Sociale",
			"CodiceFiscale": "Codice Fiscale",
			"CodiceSDI": "Codice SDI",
			"PartitaIVA": "Partita IVA",
			"Address": "Indirizzo",
			"ComuneId": "Città",
			"PostalCode": "CAP",
			"Name": "Nome",
			"Surname": "Cognome",
			"CountryId": "Stato di residenza",
			"NonItalianCityName": "Nome città estera"
		},
		"alertMissingBillingInfo": "Dati di fatturazione mancanti",
		"confirmDeleteBillingInfo": "Sei sicuro di voler cancellare i dati di fatturazione?"
	}
}
</i18n>
